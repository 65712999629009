<template>
  <div class="lesson" v-loading="pageLoading">
    <div class="nav" v-if="category.length > 1 && $route.query.id != 156">
      <p>分类</p>
      <div
        class="item"
        v-for="(item, index) of category"
        :key="item.id"
        @click="getList(index)"
        :class="{ active: classify == index }"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="lessonMain">
      <div class="right">
        <van-list
          v-loading="listLoading"
          v-if="list.length"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div class="list">
            <div
              class="card"
              v-for="item of list"
              :key="item.id"
              @click="jumpDetail(item)"
            >
              <div class="content">
                <div class="tip">
                  <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
                  <img :src="$free" v-if="item.is_free == 2" />
                  <img :src="$vip" v-else-if="item.is_vip_flat == 1" />
                  <img :src="$noVip" v-else />
                </div>
                <van-image class="cover" fit="cover" :src="item.cover_mobile">
                  <template v-slot:error>
                    <van-image
                      style="width: 100%; height: 100%"
                      fit="cover"
                      :src="$placeImgBg"
                    ></van-image>
                  </template>
                </van-image>
                <div class="detail">
                  <p class="title">
                    {{ item.name }}
                  </p>
                  <p class="text">
                    {{ item.description }}
                  </p>
                  <p class="place"></p>
                  <div class="handle">
                    <p class="price">
                      <!-- ￥<b>{{item.price}}</b><span>￥{{item.price}}</span> -->
                    </p>
                    <div class="btn">{{ $route.query.id=='238' || $route.query.id=='239' ? '查看详情' : '立即学习' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty class="emtpyMain" v-if="!list.length" description="暂无数据" />
      </div>
      <div class="left">
        <div class="classify">
          <h2 class="classify-title">
            <img
              src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/hot.jpg"
            />热门课程
          </h2>
          <div
            class="hot-item"
            v-for="(item, index) of topData"
            :key="index"
            @click="jumpDetail(item)"
          >
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="item.is_free == 2" />
              <img :src="$vip" v-else-if="item.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <van-image
              class="cover"
              fit="cover"
              :error-icon="$placeImgSmall"
              :loading-icon="$placeImgSmall"
              :src="item.master_cover"
            />
            <div class="detail">
              <p class="title">{{ item.name }}</p>
              <p class="subtitle ellipsis_two">{{ item.description }}</p>
            </div>
          </div>
          <van-empty
            class="emptySmall"
            style="margin: 0 auto"
            v-if="!topData.length"
            description="暂无数据"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      active: 0,
      list: [],
      tag: 1,
      classify: 0,
      certificate: [],
      topData: [],
      category: [],
      loading: false,
      pageLoading: true,
      finished: true,
      more: false,
      ideaNavData: [],
      ideaAllData: [],
      listLoading: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onLoad);
    this.getData();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    async getList(index) {
      this.classify = index;
      this.list = this.category[this.classify].list[0];
    },
    async getData() {
      let res2 = await this.$request.tuoyuHotList({
        flat_id: this.plat,
        limit: 3,
      });
      this.topData = res2.data.top_list || [];

      this.pageLoading = true;
      let res = await this.$request.tuoyuLessonList({
        category_id: this.$route.query.id,
        flat_id: this.plat,
      });
      this.pageLoading = false;
      if (res.code == 0) {
        // this.topData = res.data.top_list;
        this.category = res.data.list[0].list;
        let index = this.$route.query.index || 0;
        if (this.$route.query.id == 156) {
          this.category.forEach((item) => {
            this.list.push(...item.list[0]);
          });
        } else {
          this.getList(index);
        }
        // await this.getList(
        //   this.$route.query.index < 9 ? this.$route.query.index : 0
        // );
      } else {
        this.$toast(res.msg);
      }
    },
    onLoad() {},
    jumpDetail(item) {
      this.$router.push(`./detail2?id=${item.id}`);
      // if(this.$until.loginRequire()){
      //   this.$until.toPageOpen(`/detail2?id=${item.id}`)
      // }
    },
    getMore() {
      if (this.more) {
        return;
      }
      this.more = true;
      if (this.menu == 2) {
        axios
          .get(`/api/platform/pc/category?flat_id=${this.plat}&page=${this.page}`, {})
          .then((res) => {
            this.more = false;
            console.log(res);
            if (res.code == 0) {
              let list = res.data.category[this.classify].goods;
              this.list = this.list.concat(list);
              this.loading = false;
              if (list.length < 10) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            } else {
              this.$toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.more = false;
          });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  min-height: calc(100vh - 230px);
  padding-top: 20px;
  overflow: hidden;

  .lessonMain {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .left {
    width: 344px;
    overflow: hidden;
  }

  .classify {
    background: #fff;
    border-radius: 12px;
    padding: 10px;

    .classify-title {
      font-size: 20px;
      line-height: 1;
      display: flex;
      align-items: center;
      margin: 0;
      padding-top: 10px;
      img {
        margin-right: 10px;
      }
    }

    .classify-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px 10px;
    cursor: pointer;
    position: relative;
    &:not(:last-of-type) {
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/line2.jpg")
        bottom no-repeat;
    }
    .tip {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      img {
        width: 40px;
      }
    }
    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img {
        border-radius: 6px;
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 8px 0;
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    flex: 0 0 817px;
  }
  .nav {
    margin-bottom: 20px;
    width: 100%;
    padding-left: 21px;
    > p {
      font-size: 16px;
      margin-right: 16px;
      display: inline-block;
    }
    .item {
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      margin-right: 10px;
      display: inline-block;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      color: #454545;
      &:hover {
        background: #0080d6;
        color: #fff;
      }
    }
    .active {
      background: #0080d6;
      color: #fff;
    }
  }
  .list {
    padding-left: 21px;
  }
  .card {
    background: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    height: 154px;
    &:hover {
      box-shadow: 0px 0px 21px rgba($color: #989ca4, $alpha: 0.18);
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      width: 100%;
      position: relative;
      .tip {
        position: absolute;
        top: 0;
        left: 0px;
        z-index: 1;
        img {
          width: 50px;
          // height: 35px;
        }
      }
      .cover {
        flex: 0 0 238px;
        height: 134px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: auto;
        margin-right: 8px;
        padding: 10px 8px 10px 20px;
        .text {
          font-size: 14px;
          color: #7f7f7f;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: pre-wrap;
        }

        .more {
          font-size: 14px;
          color: #175199;
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .place {
        flex: 1;
      }
      .handle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .price {
          color: #ff6c00;
          font-size: 12px;
          b {
            font-size: 24px;
            margin-right: 8px;
          }
          span {
            color: #7f7f7f;
            display: block;
            text-decoration: line-through;
            display: inline;
            display: none;
          }
        }
      }

      .btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 34px;
        font-weight: 500;
        border-radius: 6px;
        color: #0080d6;
        background: #f7f7f7;
        width: 156px;
        height: 34px;
        text-align: center;
        font-size: 14px;

        &:hover {
          color: #fff;
          background: #0080d6;
        }
      }
    }
  }
}
</style>

<style lang="scss"></style>
